import * as React from "react"
import Layout from "../components/layout"
import Blog from "../components/Blog/Blog"
import ConcertAgenda from '../components/ConcertAgenda/ConcertAgenda'
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import '../css/main.css'

const IndexPage = () => {

  return (
    <>
      <Seo 
        title="Home" 
        description="Quentin Rychner is a multifaceted musician: oboist, teacher, and composer. He plays in symphonic orchestras and teaches privately in The Hague. Quentin Rychner is een veelzijdige musicus: hoboïst, docent en componist. Hij speelt in symfonieorkesten en geeft les in Den Haag."
        />
      <Layout >
        <section className="main">
          <div className="banner">
            <StaticImage 
              src="../images/banner/banner.png" 
              alt="quentin rychner performing live"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={5/2}
              fit="cover"
            >
            </StaticImage>
          </div>
          <section className="quote">
            <blockquote className="quote-text">
            S'il y a quelqu'un qui doit tout à Bach, c'est bien Dieu. 
              <cite> - Cioran</cite>
            </blockquote>
          </section>
            
          <section className="main-content">
            <Blog />
            <ConcertAgenda className="concert-agenda"/>
          </section>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage
