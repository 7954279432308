import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import './blog.css'

const Blog = () => {
    

    const data = useStaticQuery(graphql`
    query getPosts {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(blog)/"}, frontmatter: {date: {ne: "null"}}}
        sort: {order: DESC, fields: frontmatter___date}
      ) {
        edges {
          node {
            frontmatter {
              date(formatString:  "MMMM DD, YYYY")
              description
              title
              author
            }
            id
            html
          }
        }
      }
    }
      `)

    return (
      <div>
        <h2 className="sub-title">Nieuws</h2>
        <div className="blog"> 
          {data.allMarkdownRemark.edges.map(post => (
            <div key={ post.node.id } className="post-item">
              <h3 className="post-title">{post.node.frontmatter.title}</h3>
              <small className="small">Posted by {post.node.frontmatter.author} on {post.node.frontmatter.date}</small>
                <div 
                  dangerouslySetInnerHTML={{ __html: post.node.html }}
                  className="post-text">
                </div>
            </div>
            )).slice(0,10)} 
        </div> 
      </div>
    )
}


export default Blog