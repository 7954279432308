import React from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import './concert-agenda.css'

function ConcertAgenda() {
    const data = useStaticQuery(graphql`
    query getConcerts {
        allMarkdownRemark(
          filter: {fileAbsolutePath: {regex: "/(concerts)/"}, frontmatter: {date: {ne: "null"}}}
          sort: {order: ASC, fields: frontmatter___date}
        ) {
          edges {
            node {
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                website
                venue
                location
                description
              }
              html
              id
            }
          }
        }
      }
      `)

      const concertsJSX = <div className="concert-agenda">
      <h2 className="sub-title">Agenda</h2>
      {data.allMarkdownRemark.edges.map(concert => (
          <div key={ concert.node.id } className="concert-item">
            <span className="item-details">Date: </span>{concert.node.frontmatter.date} <br />
            <span className="item-details">Venue: </span>{concert.node.frontmatter.venue} <br />
            <span className="item-details">Location: </span>{concert.node.frontmatter.location} <br />
            <span className="item-details">website: </span><a href={concert.node.frontmatter.website}>{concert.node.frontmatter.website}</a> <br />
            <p>{concert.node.frontmatter.description}</p>
              <div 
                dangerouslySetInnerHTML={{ __html: concert.node.html }}
                className="concert-text"></div>
          </div>
          )).slice(0,5)} 

  </div>


  return (
      <>{data && concertsJSX}</>
    
  )
}

export default ConcertAgenda;
